import React from 'react'
import { Link } from 'react-router-dom'
import { FaStar } from "react-icons/fa";


export default function Footer() {
    return (
        <>
            <footer class="footer bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="footer__about">
                                <div class="footer__logo">
                                    <Link to="/"><img src="/images/eyemasterLogo.png" alt="" /></Link>
                                </div>
                                {/* <p>The customer is at the heart of our unique business model</p> */}
                                {/* <a href="#"><img src="/Assetes/img/payment.png" alt="" /></a> */}
                            </div>
                        </div>
                        {/* <div class="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
                            <div class="footer__widget">
                                <h6>Shopping</h6>
                                <ul>
                                    <li><a href="#">Lens Store</a></li>
                                    <li><a href="#">Trending EyeWears</a></li>
                                    <li><a href="#">Accessories</a></li>
                                    <li><a href="#">Sale</a></li>
                                </ul>
                            </div>
                        </div> */}
                        {/* <div class="col-lg-2 col-md-3 col-sm-6">
                            <div class="footer__widget">
                                <h6>Shopping</h6>
                                <ul>
                                    <li><a href="#">Contact Us</a></li>
                                    <li><a href="#">Payment Methods</a></li>
                                    <li><a href="#">Delivery</a></li>
                                    <li><a href="#">Return & Exchanges</a></li>
                                </ul>
                            </div>
                        </div> */}
                        {/* <div class="col-lg-3 offset-lg-1 col-md-6 col-sm-6">
                            <div class="footer__widget">
                                <h6>NewLetter</h6>
                                <div class="footer__newslatter">
                                    <p>Be the first to know about new arrivals, look books, sales & promos!</p>
                                    <form action="#">
                                        <input type="text" placeholder="Your email" />
                                        <button type="submit"><span class="icon_mail_alt"></span></button>
                                    </form>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <div class="footer__copyright__text">
                                {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                                <p>Copyright ©
                                    2024 Eyemast<FaStar color='#007bff'/>r
                                    All rights reserved
                                </p>
                                {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
