import React, { useEffect, useState } from 'react'
import { json, Link, NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CgProfile } from "react-icons/cg";
import { FaStar } from "react-icons/fa";

export default function Header() {
    let { pathname } = useLocation();
    const userInfo = JSON.parse(localStorage.getItem('UserInfo'));
    const userID = JSON.parse(localStorage.getItem("UserID"));

    let userId = userInfo?.id;
    console.log(pathname)


    const navigate = useNavigate();
    return (
        <>
            <div className='sticky-top w-100 top-0 bg-light'>
                <div className='container'>
                    <div className='d-flex justify-content-between align-items-center py-2 px-1'>
                        <Link to="/"><img src="/images/eyemasterLogo.png" width='90px' alt="Eyemaster Logo" /></Link>
                        {pathname == '/'? userID?.id ? <CgProfile className="pointer mt-md-0 mt-3" size={28} onClick={() => { navigate("/profileView") }} />
                            : <button className='btn btn-primary mt-3' onClick={() => { navigate("/signup") }} >
                                Register As Eyemast<FaStar color='#FFD700' />r
                            </button>:''}
                    </div>
                </div>
                {/* <nav class="navbar container navbar-expand-lg navbar-light bg-light sticky-top top-0 p-3">
                    <Link to="/"><img src="/images/eyemasterLogo.png" width='60px' alt="Eyemaster Logo" /></Link>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mr-auto"> */}
                {/* <li class="nav-item active">
                            <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Link</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                                Dropdown
                            </a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Something else here</a>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link disabled">Disabled</a>
                        </li> */}
                {/* </ul>
                        {userID?.id ? <CgProfile className="pointer mt-md-0 mt-3" size={28} onClick={() => { navigate("/profileView") }} />
                            : <button className='btn btn-primary mt-3' onClick={() => { navigate("/signup") }} >
                                Register As Professional
                            </button>} */}
                {/* </div>
                </nav > */}
            </div >
        </>
    )
}
