import React from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import Home from '../Pages/Home/Home'
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
import Shop from '../Pages/Shop/Shop'
import Product from '../Pages/Shop/Product'
import Cart from '../Pages/Cart/Cart'
import CheckOut from '../Pages/CheckOut/CheckOut'
import Professional from '../Pages/Auth/Professional'
import Login from '../Pages/Auth/Login'
import Profile from '../Pages/Auth/Profile'
import OTPVerify from '../Pages/Auth/OTPVerify'
import ProfileView from '../Pages/Auth/ProfileView'
import HomePage from '../Pages/Home/HomePage'
import PaymentPage from '../Pages/PaymentPage/PaymentPage'
import PaymentSuccess from '../Pages/PaymentPage/PaymentSuccess'

export default function Index() {


    const userToken = JSON.parse(localStorage.getItem("UserDetail"));

    const navigate = useNavigate();

    return (
        <>
            <Header />
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/shop' element={<Shop />} />
                <Route path='/shop/product' element={<Product />} />
                <Route path='/cart' element={<Cart />} />
                <Route path='/checkout' element={<CheckOut />} />
                <Route path='/signup' element={<Professional />} />
                <Route path='/verifyOTP' element={<OTPVerify />} />
                <Route path='/profileView' element={<ProfileView />} />
                <Route path='/payment' element={<PaymentPage />} />
                <Route path='/success' element={<PaymentSuccess />} />
                <Route path='/professionalProfile' element={
                    userToken?.token ? <Profile /> : <Navigate to="/" />
                } />


            </Routes>
            <Footer />
        </>
    )
}
