import React, { useState } from 'react'
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom'
import { addOTP, professionalSignup } from '../../Services/AllApi';
import toast from 'react-hot-toast';
import { FaStar } from "react-icons/fa";



const Professional = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        mobile: "",
        password: "",
        confirmPassword: "",
        countryCode: "+91",
    })



    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value === " ") {
            e.target.value = "";
        }
        else {
            setFormData((pre) => {
                return {
                    ...pre,
                    [name]: value,
                };
            });
        }
    }
    const fullMobileNumber = `${formData.countryCode}${formData.mobile}`;

    const handleSignup = async (e) => {
        e.preventDefault();

        if (formData?.password !== formData?.confirmPassword) {
            toast.error("Confirm Password didn't match with password");
            return
        }
        const fullMobileNumber = `${formData.countryCode}${formData.mobile}`;

        const payload = {
            email: formData.email,
            password: formData.confirmPassword,
            role: "professional",
            mobile: fullMobileNumber
        }
        const payload2 = {
            mobile: fullMobileNumber
        }

        try {
            setLoading(true)
            const response = await addOTP(payload2);
            toast.success("OTP has been sent on your Mobile Number")
            setFormData("");
            navigate("/verifyOTP", { state: { payload } });
            setLoading(false)
            setFormData({
                email: "",
                confirmPassword: "",
                mobile: "",
                password: "",
            });
        } catch (error) {
            console.log(error)
            setLoading(false)
            const errorMessage = error?.response?.data?.message;
            toast.error(errorMessage);
                       
        }

    }


    return (

        <div className='registrationBanner p-md-5 p-3'>
            <div className='container'>
                <form onSubmit={handleSignup}>
                    <div className='row '>
                        <div className='col-lg-6 col-md-10 col-12 px-md-3 px-0 py-4 rounded bg-white'>
                            <div className='heading mx-3'>Register As Eyemast<FaStar color='#007bff'/>r</div>
                            <div className='text-gray mx-3'>Fill in the registration data. It will take a couple of minutes. </div>
                            <div className='col-12 form-group mt-3'>
                                <label className='labelHeading'>Mobile Number<span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <select
                                        name="countryCode"
                                        value={formData.countryCode}
                                        className="form-select pointer"
                                        onChange={handleChange}
                                    >
                                        <option value="+91" className='pointer'>IN(+91)</option>
                                        <option value="+971" className='pointer'>UAE(+971)</option>
                                    </select>
                                    <input
                                        type='number'
                                        placeholder='Enter your Mobile Number'
                                        required
                                        name='mobile'
                                        value={formData.mobile}
                                        className="form-control"
                                        onChange={handleChange}
                                        // onInput={(e) => {
                                        //     e.target.value = e.target.value.slice(0, 10);
                                        // }}
                                    />
                                </div>
                            </div>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Email<span className='text-danger'>*</span></label>
                                <input
                                    placeholder='Enter your Email'
                                    required
                                    className="form-control"
                                    name='email'
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Create Password<span className='text-danger'>*</span></label>
                                <div className="input-group position-relative">
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='Enter your Password'
                                        required
                                        name='password'
                                        className="form-control py-2 rounded"
                                        id="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        style={{ paddingRight: '45px' }}
                                    />
                                    <span
                                        className="position-absolute iconStyle"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                                    </span>
                                </div>
                            </div>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Confirm Password<span className='text-danger'>*</span></label>
                                <div className="input-group position-relative">
                                    <input
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        placeholder='Confirm your Password'
                                        required
                                        className="form-control py-2 rounded"
                                        name="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        style={{ paddingRight: '45px' }}
                                    />
                                    <span
                                        className="position-absolute iconStyle"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    >
                                        {showConfirmPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                                    </span>
                                </div>
                            </div>
                            <div className='col-12 mt-5'>
                                <button
                                    className='btn btn-primary w-100'
                                    type='submit'
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        "Sign Up"
                                    )}
                                </button>
                            </div>
                            {/* <div className='mb-5 mt-3 text-center'>
                                Already have an account ? <span className='pointer' onClick={() => { navigate("/login") }} style={{ color: '#007bff' }}>Login</span>
                            </div> */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Professional