import React from 'react'

export default function HomePage() {
    let slider1 = [
        { name: 'Eyeglasses', img: '/images/1.jpg' },
        { name: 'Sunglasses', img: '/images/2.jpg' },
        { name: 'Contact Lenses', img: '/images/lenc1.jpg' },
        { name: 'Screen Glasses', img: '/images/3.jpg' },
        { name: 'Power Sunglasses', img: '/images/power1.jpg' },
        { name: 'Progressive Lenses', img: '/images/prog1.jpg' },
    ]
    return (
        <>
            <div className='topHeaderContainer'>
                {slider1?.map((res, index) => {
                    return (
                        <>
                            <div className='slideritem1' key={index}>
                                <div className='sliderimg1'>
                                    <img src={res?.img} className='w-100 h-100' alt='' />
                                </div>
                                <div className='text-center'>
                                    <div className='slidername1'>{res?.name}</div>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
            <div className='bannerSection'>
                <div className='container'>
                    <div className='row py-md-5 py-sm-4 pt-4'>
                        <div className='col-lg-6 col-md-7 col-sm-7 col-8 py-lg-5 py-md-4'>
                            <div className='banner1text'>Sunglasses</div>
                            <button className='cs-btn'>Coming Soon!</button>
                        </div>
                        <div className='col-lg-6 col-md-5 col-sm-5 col-4'></div>
                    </div>
                </div>
            </div>
            {/* <div className='w-100 my-md-4 my-sm-4 my-3'>
                <img src='/images/banner3.jpg' className='w-100' alt=''/>
            </div> */}
            <div className='w-100 my-md-4 my-sm-4 my-3'>
                <img src='/images/banner2.jfif' className='w-100' alt=''/>
            </div>

        </>
    )
}
