import React, { useState, useEffect } from 'react'
import { deleteImg, professionalView, profileData, uploadImg } from '../../Services/AllApi';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Components/Loader/Loader';

const ProfileView = () => {
    const userToken = JSON.parse(localStorage.getItem("UserDetail"));
    const [profileImage, setProfileImage] = useState([]);
    const [imgURl, setImgURl] = useState("")
    const [img, setImg] = useState("");
    const [delImg, setDelImg] = useState([]);
    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);


    const [isLoading, setIsLoading] = useState(true)
    const [isDisabled, setIsDisabled] = useState(true);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        prefix: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
        address: "",
        eyetest: "",
        experience: "",
        organization: "",
        qualification: "",
        profession: "",
        speciality: "",
        company: "",
        lamp: false
    })



    useEffect(() => {
        const config = {
            headers: {
                Authorization: `Bearer ${userToken?.token}`
            }
        };
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await professionalView(config);
                let info = response?.data?.user;
                console.log(info, "details--")
                if (info) {
                    setFormData({
                        name: info?.firstname || "",
                        prefix: info?.prefix || "",
                        pincode: info?.pinCode || "",
                        city: info?.city || "",
                        state: info?.state || "",
                        country: info?.country || "India",
                        address: info?.address || "",
                        eyetest: info?.perDayEyeTest || "",
                        experience: info?.yearsOfExperience || "",
                        organization: info?.organization || "",
                        qualification: info?.qualification || "",
                        profession: info?.profession || "",
                        speciality: info?.speciality || "",
                        company: info?.company || "",
                        lamp: info?.slitLampAvailable || false,
                    });

                    setProfileImage(info?.profile?.fileUrl);
                    setDelImg([info?.profile?.fileName]);
                    setImgURl(info?.profile?.fileName)
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        }

        fetchData();
    }, [])



    const handleFileInput = (e) => {
        const file = e.target.files[0];

        if (file) {
            setImg(file);
            const imageUrl = URL.createObjectURL(file);
            setProfileImage(imageUrl);
            setCheck(true);
        }
        else {
            setCheck(false)
        }
    };


    const handleImage = async () => {
        if (!img) {
            return;
        }
        const formData = new FormData();
        formData.append('upload', img);
        try {
            const response = await uploadImg(formData);
            return response
        } catch (error) {
            console.log(error);
        }
    }


    const handleDelImage = async () => {
        let payload = {
            data: delImg
        };
        try {
            const response = await deleteImg(payload);
            setDelImg([]);
            console.log(response, "iamge deleting---")
            console.log()
        } catch (error) {
            console.log(error)
        }

    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("Selected Value:", value);
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        // if (name === 'state') {
        //     fetchCities(value);
        // }
    };


    const handleEdit = async (e) => {

        e.preventDefault();
        const config = {
            headers: {
                Authorization: `Bearer ${userToken?.token}`
            }
        };

        try {
            setLoading(true)
            if (check) {
                handleDelImage();
                const imageURl = await handleImage();
                console.log(imageURl, "image uploaded---")
                let fileName = imageURl?.data?.data[0]?.fileName;
                let fileUrl = imageURl?.data?.data[0]?.fileUrl;
                console.log(fileName, "filenmae");
                console.log(fileUrl, "fileurl");

                const payload = {
                    profile: {
                        fileName: fileName,
                        fileUrl: fileUrl
                    },
                    date_of_birth: "",
                    firstname: formData.name,
                    prefix: formData.prefix,
                    city: formData.city,
                    country: formData.country,
                    state: formData.state,
                    pinCode: formData.pincode,
                    speciality: formData.speciality,
                    profession: formData.profession,
                    qualification: formData.qualification,
                    company: formData.company,
                    yearsOfExperience: formData.experience,
                    perDayEyeTest: formData.eyetest,
                    slitLampAvailable: formData.lamp,
                    address: formData.address,
                    lat_long: {
                        lat: "",
                        long: ""
                    }
                }

                const response = await profileData(payload, config);
                console.log(response, "profile data");
                toast.success("Your Professional Profile has been Updated!")
                navigate("/profileView");
                setLoading(false)
                setCheck(false);
            }
            else {
                const payload = {
                    profile: {
                        fileName: imgURl,
                        fileUrl: profileImage
                    },
                    date_of_birth: "",
                    firstname: formData.name,
                    prefix: formData.prefix,
                    city: formData.city,
                    country: formData.country,
                    state: formData.state,
                    pinCode: formData.pincode,
                    speciality: formData.speciality,
                    profession: formData.profession,
                    qualification: formData.qualification,
                    company: formData.company,
                    yearsOfExperience: formData.experience,
                    perDayEyeTest: formData.eyetest,
                    slitLampAvailable: formData.lamp,
                    address: formData.address,
                    lat_long: {
                        lat: "",
                        long: ""
                    }
                }
                const response = await profileData(payload, config);
                console.log(response, "profile data");
                toast.success("Your Professional Profile has been Updated!")
                navigate("/profileView");
                setLoading(false);
                setCheck(false);
            }
        }
        catch (error) {
            setLoading(false)
            console.log(error)
        }
    }



    return (
        <React.Fragment>
            {/* {isLoading ? <Loader open={isLoading} /> : ""} */}
            <div className='container'>
                <form onSubmit={handleEdit}>
                    <div className='mt-5 heading'>Professional Profile </div>
                    <div className='row mt-4'>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>
                                    Profile Picture
                                </label>
                                <input
                                    type="file"
                                    accept="image/*"

                                    className="form-control-file pointer"
                                    name='profile'
                                    onChange={handleFileInput}
                                />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 pl-md-0 pl-4'>
                            <div className="row">
                                <div className='col-6 pl-md-0 pl-4'>
                                    {profileImage && (
                                        <div className="mt-0">
                                            <img
                                                src={profileImage}
                                                alt="Profile Preview"
                                                style={{ maxWidth: '100%', maxHeight: '100px', objectFit: 'contain' }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='col-6 d-flex align-items-center justify-content-end justify-content-md-center'>
                                    <span className="btn btn-primary" onClick={() => { setIsDisabled(false) }} >Edit</span>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className='row mt-5 mb-5'>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Prefix</label>
                                <select
                                    name="prefix"
                                    className="form-control pointer"
                                    placeholder="Select Your Prefix"
                                    value={formData.prefix}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected>
                                        Select Your Prefix
                                    </option>
                                    <option value="Mr." className='pointer'>Mr.</option>
                                    <option value="Ms." className='pointer'>Ms.</option>
                                    <option value="Dr." className='pointer'>Dr.</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Full Name</label>
                                <input
                                    placeholder='Enter Your Full Name'
                                    disabled={isDisabled}
                                    className="form-control"
                                    name='name'
                                    type="text"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Speciality</label>
                                <select
                                    name="speciality"
                                    className="form-control pointer"
                                    placeholder='Select Your Speciality'
                                    value={formData.speciality}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected>
                                        Select Your Speciality
                                    </option>
                                    <option value="Refraction" className='pointer'>Refraction</option>
                                    <option value="Paediatric" className='pointer'>Paediatric</option>
                                    <option value="Medicines" className='pointer'>Medicines</option>
                                    <option value="Surgery" className='pointer'>Surgery</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Profession</label>
                                <select
                                    name="profession"
                                    className="form-control pointer"
                                    placeholder="Select Your Profession"
                                    value={formData.profession}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected>
                                        Select Your Profession
                                    </option>
                                    <option value="Ophthalmologist" className='pointer'>Ophthalmologist</option>
                                    <option value="Optometrist" className='pointer'>Optometrist</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Qualification</label>
                                <select
                                    name="qualification"
                                    className="form-control pointer"
                                    placeholder="Select Your Qualification"
                                    value={formData.qualification}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected>
                                        Select Your Profession
                                    </option>
                                    <option value="Doctorate" className='pointer'>Doctorate</option>
                                    <option value="Masters" className='pointer'>Masters</option>
                                    <option value="Graduate" className='pointer'>Graduate</option>
                                    <option value="Diploma" className='pointer'>Diploma</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Eye Care Organization</label>
                                <input
                                    placeholder='Enter Your Company Name'

                                    className="form-control"
                                    name='company'
                                    type="text"
                                    value={formData.company}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Years of Experience</label>
                                <input
                                    placeholder='Enter Your Experience'

                                    className="form-control"
                                    name='experience'
                                    type="number"
                                    value={formData.experience}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Per Day Eye Test in Current Practice</label>
                                <input
                                    placeholder='Enter Your Count'

                                    className="form-control"
                                    name='eyetest'
                                    type="number"
                                    value={formData.eyetest}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Slit Lamp Available</label>
                                <select
                                    name="lamp"
                                    className="form-control pointer"
                                    placeholder="Select the lamp availability"
                                    value={formData.lamp}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected>
                                        Select the Lamp avaailability
                                    </option>
                                    <option value="true" className='pointer'>Yes</option>
                                    <option value="false" className='pointer'>No</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Country</label>
                                <input
                                    placeholder='Country'

                                    className="form-control"
                                    name='country'
                                    type="text"
                                    value={formData.country}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Address</label>
                                <input
                                    placeholder='Enter Your Address'

                                    className="form-control"
                                    name='address'
                                    type="text"
                                    value={formData.address}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>State</label>
                                <select
                                    name="state"
                                    className="form-control pointer"
                                    value={formData?.state}
                                    disabled={isDisabled}
                                    onChange={handleChange}

                                >
                                    <option value="" disabled>Select Your State</option>
                                    {/* {states?.map((state) => (
                                   <option key={state?.isoCode} value={state?.name}>
                                       {state?.name}
                                   </option>
                               ))} */}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>City</label>
                                <select
                                    name="city"
                                    className="form-control pointer"
                                    value={formData.city}
                                    disabled={isDisabled}
                                    onChange={handleChange}

                                >
                                    <option value="" disabled>Select Your City</option>
                                    {/* {cities?.map((city) => (
                                   <option key={city?.name} value={city?.name}>
                                       {city?.name}
                                   </option>
                               ))} */}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='col-12 form-group'>
                                <label className='labelHeading'>Pin Code</label>
                                <input
                                    placeholder='Enter Your Pincode'
                                    // 
                                    className="form-control"
                                    name='pincode'
                                    type="number"
                                    value={formData.pincode}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                    onInput={(e) => {
                                        e.target.value = e.target.value?.slice(0, 6);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end mt-5 mb-5'>
                        <button
                            className='btn btn-primary w-25'
                            type='submit'
                            disabled={loading}
                        >
                            {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                "Update"
                            )}

                        </button>
                    </div>
                </form>
            </div>
        </React.Fragment>

    )
}

export default ProfileView