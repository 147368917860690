import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

export default function PaymentSuccess() {
    const navigate = useNavigate()
    return (
        <>
            <div className='w-100 py-5' style={{overflowX:'hidden'}}>
                <div className='row justify-content-center'>
                    <div className='col-md-6'>
                        <div className='w-100 py-5'>
                            <div className='d-flex justify-content-center pb-2'>
                                <FaRegCheckCircle size={45} color='#007bff' className='text-center' />
                            </div>
                            <h3 className='text-center'>Payment successful</h3>
                            <div className='text-center text-gray mt-1'>Thank you for joining as Eyemast⭐️r.</div>
                            <div className='text-center text-gray'>You Expertise will be visible now  to customers & Patients!!</div>
                            <div className='d-flex justify-content-center mt-3'>
                                <button className='btn btn-sm btn-primary' onClick={()=>navigate('/')}>Back to home</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
