import axios from "axios";

import { API, baseUrl } from "./Endpoint";

export const professionalSignup = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.professionalSignup}`,
        payload
    );
};

export const professionalLogin = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.professionalLogin}`,
        payload
    );
};

export const profileData = async (payload, config) => {
    return await axios.put(
        `${baseUrl}${API.Allapi.profileData}`,
        payload, config
    );
};

export const uploadImg = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.uploadImg}`,
        payload
    );
};


export const deleteImg = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.deleteImg}`,
        payload
    );
};




export const addOTP = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.addOTP}`,
        payload
    );
}



export const verifyOTP = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.verifyOTP}`,
        payload
    );
}

export async function getProfileData(id){
    return axios.get(`${baseUrl}${API.Allapi.userData}${id}`)
}



export const professionalView = async (config) => {
    return await axios.get(
        `${baseUrl}${API.Allapi.professionalView}`,
        config
    );
}